.icon-side-bar {
  width: 25px;
  height: 25px;
}

.icon-side-bar:hover {
  cursor: pointer;
}

li:hover {
  background-color: #d8d6d6;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.active {
  -webkit-text-decoration: underline 2px blue;
  text-decoration: underline 2px blue;
}
.mybar {
  width: '250px';
  padding: '0 10px';
  position: 'fixed';
  top: '81px';
  left: '0';
  z-index: '9999';
  background-color: '#fff';
  overflow: auto;
  padding-bottom: '50px';
}

