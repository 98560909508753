.parent-chart {
  width: 240px;
  height: 310px;
}

.child-chart {
  width: 120px;
  height: 100px;
  position: relative;
  top: -165px;
  left: 45px;
  z-index: 999;
  border-radius: 50%;
}

.icon-color-total {
  color: #36a2eb;
}

.icon-color-7 {
  color: #ff6384;
}
