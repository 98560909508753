.parent-chart {
    width: 240px;
    height: 310px;
}
.child-chart {
  width: 120px;
  height: 100px;
  position: relative;
  top: -165px;
  left: 45px;
  z-index: 999;
  border-radius: 50%;
}
.icon-color-total {
  color: rgb(54, 162, 235);
}
.icon-color-7 {
  color: rgb(255, 99, 132);
}

  /*# sourceMappingURL=home.css.map */