

/* search styling */
.nav{
    text-align: center;
    margin-top: 20px;
}
.action-button {
    background-color: #008cba;
    border: 2px solid #008cba;
    color: white;
    padding: 7px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.action-button:hover {
    background-color: white;
    color: black;
}
.action-button:hover {
    background-color: white;
    color: black;
}

.search-input {
    width: 50%;
    height: 37px;
    font-size: 17px;
}

/* body notes styling */
.container-show-notes{
    margin-top: 25px;
}
.show-note:nth-child(even) {
    background-color: #cecdcd;
}

.show-note{
    margin-left: 10px;
    display: grid;
    grid-template-columns: 25% 40% 25% 10%;
}
.show-note:hover{
    background-color: lightblue;
}
.font-wight{
    font-weight: bold;
}
.show-note p{
    border: 1px solid rgb(219, 216, 216);
    padding: 10px 10px;
}
.images{
    width: 20px;
    height: 20px;
    margin-left: 10px;
}
.error{
    color: #f00;
    text-align: center;
    margin-top: 5%; 
    font-size: xx-large; 
  }

  /* update, add model styling */
  * {
    font-family: Helvetica;
  }

  
  /* The Modal (background) */
  .modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
  }
  
  /* Modal Content */
  .modal-content {
    width: 60%;
    position: absolute;
    top: 20%;
    margin-left: 25%;
    background-color: #fefefe;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
  }
 
  
  /* The Close X Button */

  
 
  
  .modal-header {
    padding: 2px 16px;
    background-color: rgb(51, 51, 51);
    color: white;
  }
  
  
 
  
  .modal-footer {
    padding: 2px 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: white;
    color: white;
  }
  
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .row {
      margin-top: 10px;
      margin-bottom: 10px;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .left {
    float: left;
    width: 10%;
    text-align: left;
  }
  
  .right {
    float: left;
    width: 90%;
  }
  
  .modal-input {
    width: 100%;
    height: 32px;
    font-size: 17px;
  }
  
  .error {
    color: red; 
    text-align: center;
  }
