.icon-eye {
  position: relative;
  top: 40px;
  left: 87%;
  cursor: pointer;
  z-index: 999;
}

.icon-eye-confirm {
  position: relative;
  top: 40px;
  left: 80%;
  cursor: pointer;
  z-index: 999;
}
