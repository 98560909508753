.total-number-compalints {
  color: #f00;
}

.pending-reqests {
  color: #FFBB00;
}

.solved-requests {
  color: #0f0;
}
