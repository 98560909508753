.layout {
  overflow-x: scroll !important;
}

.container-right {
  width: 95vw;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 1200px) {
  .container-right {
    width: 110vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 1100px) {
  .container-right {
    width: 120vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 1000px) {
  .container-right {
    width: 130vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 900px) {
  .container-right {
    width: 140vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 800px) {
  .container-right {
    width: 150vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 850px) {
  .container-right {
    width: 160vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 850px) {
  .container-right {
    width: 170vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 750px) {
  .container-right {
    width: 180vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 700px) {
  .container-right {
    width: 190vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
