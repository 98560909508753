.icon-side-bar{
    width: 25px;
    height: 25px;
    &:hover{
        cursor: pointer;
    }
}
li:hover{
    background-color: rgb(216, 214, 214);
    cursor: pointer;
}
a:hover {
    text-decoration: none;
}
.active {
    text-decoration: underline 2px blue;
}