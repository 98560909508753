.logo{
    width: 5em;
    height: 5em;
}

.wrapp-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        background-color: #007BFF;
        color: #fff;
        text-transform: uppercase;
        padding: 5px;
        border-radius: 5px;
        border: none;
        &:hover {
            background-color: #0069D9;
        }
    }
}
