.logo {
  width: 5em;
  height: 5em;
}

.wrapp-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.wrapp-header button {
  background-color: #007BFF;
  color: #fff;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 5px;
  border: none;
}

.wrapp-header button:hover {
  background-color: #0069D9;
}


