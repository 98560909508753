h2 {
  font-weight: 400;
}

a,
a:link,
a:visited,
a:active {
  color: #3ca9e2;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:focus,
a:hover,
a:link:focus,
a:link:hover,
a:visited:focus,
a:visited:hover,
a:active:focus,
a:active:hover {
  color: #329dd5;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#login-form-wrap {
  background-color: #fff;
  width: 35%;
  margin: 100px auto;
  text-align: center;
  padding: 20px 0 0 0;
  border-radius: 4px;
  -webkit-box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
}

#login-form {
  padding: 0 60px;
}

#login-form div {
  position: relative;
  padding-bottom: 15px;
}

input,
.login-button {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  outline: none;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 0 0 0 10px;
  margin: 0;
  color: #8a8b8e;
  border: 1px solid #c2c0ca;
  font-style: normal;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  background: none;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border-color: #3ca9e2;
}

.eye-icon {
  position: absolute;
  left: 90%;
  top: 20%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
}

.login-button {
  border: none;
  display: block;
  background-color: #3ca9e2;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 18px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.login-button:hover {
  background-color: #329dd5;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.forget-password {
  text-align: right;
}

@media screen and (max-width: 1200px) {
  #login-form-wrap {
    background-color: #fff;
    width: 45%;
    margin: 100px auto;
    text-align: center;
    padding: 20px 0 0 0;
    border-radius: 4px;
    -webkit-box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 1000px) {
  #login-form-wrap {
    background-color: #fff;
    width: 60%;
    margin: 100px auto;
    text-align: center;
    padding: 20px 0 0 0;
    border-radius: 4px;
    -webkit-box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
  }
}
